import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../components";
import Scrollspy from "react-scrollspy";

const offer_nav_data = [
  {
    slug: "specjalizacje",
    label: "Specjalizacje",
  },
  {
    slug: "strategia",
    label: "Strategia",
  },
  {
    slug: "branding",
    label: "Branding",
  },
  {
    slug: "ux",
    label: "UX/UI",
  },
  {
    slug: "serwisy",
    label: "Serwisy internetowe",
  },
  {
    slug: "marketing",
    label: "Marketing",
  },
  {
    slug: "crm",
    label: "adreamCRM",
  },
];

const OfferPage = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Oferta",
        href: pathname,
        lang: "pl",
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/offer/",
      }}
    >
      <section className="container-fluid page_offer offer_section_1">
        <h1>Oferta</h1>
        <div className="row">
          <div className="col-md-3">
            <div className="page_offer-nav">
              <Scrollspy
                items={offer_nav_data?.map(({ slug }) => slug)}
                currentClassName="current"
                offset={150}
              >
                {offer_nav_data?.map(({ slug, label }, index) => (
                  <li className={index === 0 ? "current" : ""}>
                    <a href={`#${slug}`}>{label}</a>
                  </li>
                ))}
              </Scrollspy>
            </div>
          </div>
          <div className="col-md-8 offset-md-1">
            <span className="anchor_offer" id="specjalizacje"></span>
            <div className="page_offer-tile">
              <h3>Specjalizacje</h3>
              <div className="row">
                <div className="col-lg-8">
                  <strong>Siła doświadczenia.</strong>
                  <p>
                    Pracujemy z Klientami z wielu branż, jednak w kilku z nich
                    osiągnęliśmy wyjatkowo wysoki poziom specjalizacji, dzięki
                    czemu osiągamy fenomenalne, powtarzalne wyniki.
                  </p>
                </div>
                <div className="col-lg-4">
                  <ul>
                    <li className="link">
                      <a
                        href="https://jamstackagency.co.uk/pl/"
                        target="_blank"
                        rel="nofollow noreferrer"
                      >
                        Jamstack - szybkie, wydajne i bezpieczne serwisy
                        internetowe
                      </a>
                    </li>
                    <li className="link">
                      <Link to="/marketing-dla-deweloperow">
                        Marketing dla deweloperów
                      </Link>
                    </li>
                    <li>Marketing dla hoteli</li>
                    <li>Marketing dla e-commerce</li>
                    <li>Marketing dla gamingu</li>
                  </ul>
                </div>
              </div>
            </div>
            <span className="anchor_offer" id="strategia"></span>
            <div className="page_offer-tile">
              <h3>Strategia</h3>
              <div className="row">
                <div className="col-lg-8">
                  <strong>Realna do wdrożenia i skuteczna.</strong>
                  <p>
                    Strategię opracowuje się, aby wygrywać - dzięki niej
                    działamy na podstawie danych a nie wyobrażeń, według planu a
                    nie tylko i wyłącznie doraźnie i spontanicznie. Strategia to
                    najważniejszy element planu realizacji celów stawianych
                    przed marką, który jest fundamentem wszelkich podejmowanych
                    działań komunikacyjnych i reklamowych.
                  </p>
                  <div className="btn_wrapper">
                    <Link to="/oferta/strategia" className="btn_custom">
                      Więcej
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <ul>
                    <li>Analiza</li>
                    <li>Badania</li>
                    <li>Strategia marketingowa</li>
                    <li>Strategia komunikacji</li>
                    <li>Model Bussines Canvas</li>
                  </ul>
                </div>
              </div>
            </div>
            <span className="anchor_offer" id="branding"></span>
            <div className="page_offer-tile">
              <h3>Branding</h3>
              <div className="row">
                <div className="col-lg-8">
                  <strong>Od zera, od nowa, na nowo.</strong>
                  <p>
                    Marka to nie tylko logo - to wszystkie obszary styczności z
                    Klientem. Pojmujemy markę jako całość bez podziału na print
                    czy digital, on-line czy off-line. Tworzymy nowe marki,
                    odświeżamy wizerunek, zmieniamy pozycjonowanie. Zgodnie z
                    założeniami strategicznymi.
                  </p>
                  <div className="btn_wrapper">
                    <Link to="/oferta/branding" className="btn_custom">
                      Więcej
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <ul>
                    <li>Logo</li>
                    <li>Key Visual</li>
                    <li>Identyfikacja wizualna</li>
                    <li>Naming</li>
                    <li>Claim</li>
                    <li className="link">
                      <Link to="/projektowanie-opakowan-i-etykiet/">
                        Projektowanie opakowań i etykiet
                      </Link>
                    </li>
                    <li>Webdesign</li>
                  </ul>
                </div>
              </div>
            </div>
            <span className="anchor_offer" id="ux"></span>
            <div className="page_offer-tile">
              <h3>UX/UI</h3>
              <div className="row">
                <div className="col-lg-8">
                  <strong>
                    <span>Nie</span> licz na <span>F</span>UX
                  </strong>
                  <p>
                    Skuteczność działań osiągamy poprzez połączenie celów
                    biznesowych z realnymi potrzebami Klientów. Zapewnienie
                    wygodnego i intuicyjnego obcowania z marką zwiększa
                    pozywtyne doświadczenia Klientów, zwiększając jednocześnie
                    szanse na dokonanie oczekiwanej przez markę konwersji.
                  </p>
                  <div className="btn_wrapper">
                    <Link to="/audyt-ux" className="btn_custom">
                      Więcej
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <ul>
                    <li className="link">
                      <Link to="/audyt-ux">Audyt UX</Link>
                    </li>
                    <li>Audyt konkurencji</li>
                    <li>Test UX</li>
                    <li>Analiza ruchu</li>
                    <li>Prototypowanie</li>
                  </ul>
                </div>
              </div>
            </div>
            <span className="anchor_offer" id="serwisy"></span>
            <div className="page_offer-tile">
              <h3>Serwisy internetowe</h3>
              <div className="row">
                <div className="col-lg-8">
                  <strong>Zaplanowana skuteczność i atrakcyjny design.</strong>
                  <p>
                    Punktem wyjścia zawsze jest cel biznesowy i potrzeby
                    Klienta. Gdy zrozumiemy ideę, zweryfikujemy założenia
                    rozpoczynamy prace projektowe i wdrożeniowe. Co ważne,
                    dopasowujemy technologię do potrzeb projektu a nie na
                    odwrót. Dróg jest wiele - ważne jest, aby wybrać tą
                    prowadzącą do celu a nie koniecznie tą, którą podążają
                    wszyscy. Nie ma dla nas żadnych wymówek - ma być atrakcyjnie
                    i skutecznie. W tym samym czasie.
                  </p>
                  <div className="btn_wrapper">
                    <Link
                      to="/oferta/serwisy-internetowe"
                      className="btn_custom"
                    >
                      Więcej
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <ul>
                    <li className="link">
                      <a
                        href="https://jamstackagency.co.uk/pl/"
                        target="_blank"
                        rel="nofollow noreferrer"
                      >
                        Jamstack - szybkie, wydajne i bezpieczne serwisy
                        internetowe
                      </a>
                    </li>
                    <li className="link">
                      <a
                        href="https://webinity.com/pl/"
                        target="_blank"
                        rel="nofollow noreferrer"
                      >
                        Webinity - Administracja stronami WordPress i
                        WooCommerce
                      </a>
                    </li>
                    <li>Warsztaty projektowe</li>
                    <li>Dokumentacje</li>
                    <li>Prototypy</li>
                    <li>Serwisy i strony internetowe</li>
                    <li>Sklepy internetowe</li>
                    <li>Portale</li>
                    <li className="link">
                      <Link to="/integracja-vox-developer-crm-z-strona-www/">
                        Integracja strony z VoxDeveloper CRM
                      </Link>
                    </li>
                    <li className="link">
                      <Link to="/dedykowane-synchronizatory-integracje-systemow/">
                        Dedykowane sychronizatory oraz integracje systemów
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <span className="anchor_offer" id="marketing"></span>
            <div className="page_offer-tile">
              <h3>Marketing</h3>
              <div className="row">
                <div className="col-lg-8">
                  <strong>Wartościowy ruch, oczekiwany wizerunek.</strong>
                  <p>
                    Marketing jest pojęciem tak szerokim jak możliwości jakie
                    oferuje. Mnogość kanałów, narzędzi i dróg realizacji celów
                    wymaga każdorazowej, indywidaulnej analizy i dobrania
                    najbardziej adekwatnych rozwiązań. Ustalmy cel, a ustalimy
                    do niego drogę.
                  </p>
                  <div className="btn_wrapper">
                    <Link to="/oferta/marketing" className="btn_custom">
                      Więcej
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <ul>
                    <li className="link">
                      <Link to="/oferta/banery-reklamowe/">
                        Banery reklamowe
                      </Link>
                    </li>
                    <li className="link">
                      <Link to="/oferta/analityka-internetowa/">
                        Analityka internetowa
                      </Link>
                    </li>
                    <li className="link">
                      <Link to="/oferta/social-media/">Social Media</Link>
                    </li>
                    <li className="link">
                      <Link to="/oferta/linkedin-ads/">Linkedin Ads</Link>
                    </li>
                    <li className="link">
                      <Link to="/oferta/facebook-ads/">Facebook Ads</Link>
                    </li>
                    <li className="link">
                      <Link to="/oferta/ads-google/">Google Ads</Link>
                    </li>
                    <li className="link">
                      <Link to="/oferta/kampanie-zagraniczne/">
                        Kampanie zagraniczne
                      </Link>
                    </li>
                    <li>Spoty</li>
                  </ul>
                </div>
              </div>
            </div>
            <span className="anchor_offer" id="crm"></span>
            <div className="page_offer-tile">
              <h3>adreamCRM</h3>
              <div className="row">
                <div className="col-lg-8">
                  <strong>CRM z funkcją generowania leadów.</strong>
                  <p>
                    adreamCRM to nie kolejny system CRM. To prawdziwy
                    lead-generator dedykowany agencjom digital, kreatywnym,
                    interaktywnym oraz reklamowym. Co odróżnia adreamCRM od
                    wszystkich klasycznych narzędzi do obsługi sprzedaży?
                  </p>
                  <div className="btn_wrapper">
                    <Link to="/oferta/adream-crm" className="btn_custom">
                      Więcej
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default OfferPage;
